import { App } from "../App";

export const HomeRoute = () => {
  return (
    <App>
      <img
        src="/cats-in-tech-logo.png"
        className="cats-in-tech-logo"
        width="320"
        alt="logo"
      />
      <div className="heading">Cats In Tech</div>
      <div className="subtitle">Smart Tools for Creative People</div>
    </App>
  );
};
